import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import queryString from 'query-string';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import AdminContainer from '../../../../layout/admin/container';
import { useBreadcrumbs } from '../../../../../contexts/breadcrumbs-provider';
import Heading from '../../../../layout/admin/heading';
import { getApiRequest, postApiRequest, apiUrl } from '../../../../../utils/request';
import { isBrowser, formatDateTime } from '../../../../../utils/helpers';
import Loadable from '../../../../data/loadable';
import AdminTable from '../common/admin-table';
import AutoForm from '../../../../form/auto-form';
import SEO from '../../../../layout/seo';


function buildDownloadUrl(token) {
  let concatenated = `${apiUrl}/admin/users/csv?token=${token}`;
  return concatenated.replace('/api/admin', '/admin');
}

function getOptionRange(start, end) {
  const ret = [{
    value: '',
    label: 'Select...',
  }];
  for (let i = start; i <= end; i++) {
    ret.push({
      value: i,
      label: i,
    });
  }
  return ret;
}

function formatRow(item) {
  const {id, birthday_day: bdayDay, birthday_month: bdayMonth, birthday_year: bdayYear, created_at: created, email, name, phone: phoneRaw, role } = item;
  const bday = bdayDay ? `${bdayMonth}/${bdayDay}/${bdayYear}` : 'NA';
  const { name: roleName } = role;
  const phone = phoneRaw ? parsePhoneNumberFromString(phoneRaw).formatNational() : '';
  return {
    name,
    email,
    phone,
    created: formatDateTime(created),
    bday,
    role: roleName,
    id,
  };
}

const AdminUsers = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [queryParamsString, setQueryParamsString] = useState(null);
  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/app/admin/users',
        label: 'Users',
      },
    ]);   
  }, []);

  const query = queryString.parse(isBrowser ? window.location.search : '');
  const currentQueryParamsString = queryString.stringify(query || {});

  const loadUsers = async () => {
    if (!loading) {
      setLoading(true);
    }
    const result = await getApiRequest(`/admin/users${queryParamsString ? `?${queryParamsString}` : ''}`);
    // setUsers(result.data);
    setResults(result);
    setLoading(false);
  };

  useEffect(() => {
    if (currentQueryParamsString !== queryParamsString) {
      setQueryParamsString(currentQueryParamsString);
    }
  }, [currentQueryParamsString]);


  useEffect(() => {
    if (queryParamsString !== null && queryParamsString === currentQueryParamsString) {
      loadUsers();
    }
  }, [queryParamsString]);

  const downloadCsv = async () => {
    try {
      const result = await postApiRequest('/auth/one-time-token', {});
      const { code: authcode } = result;
      window.open(buildDownloadUrl(authcode), '_blank');
    } catch (err) {
      console.log(err, 'error trying to download...');
    }
  }

  const columns = [
    {
      key: 'name',
      label: 'Name',
      isBold: true,
    },
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'phone',
      label: 'Phone',
    },
    {
      key: 'role',
      label: 'Role',
      isBold: true,
    },
    {
      key: 'bday',
      label: 'Birthday',
    },
    {
      key: 'created',
      label: 'Created',
    },
    {
      key: 'actions',
      label: '',
      isRight: true,
      render: (row) => (
        <Link
          to={`/app/admin/user/edit?id=${row.id}`}
          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Edit
        </Link>
      ),
    },
  ];

  const actionLinks = [
    {
      label: 'Download',
      onClick: downloadCsv,
      secondary: true,
    },
    {
      label: 'Create User',
      href: '/app/admin/user',
    },
  ];

  const baseUrl = '/app/admin/users';

  const searchForm = {
    type: 'get',
    noContainer: true,
    successRedirect: '/app/admin/users',
    fields: [
      {
        name: 'name',
        label: 'Name',
        type: 'text',
      },
      {
        name: 'email',
        label: 'Email',
        type: 'email',
      },
      {
        name: 'role_id',
        label: 'Role',
        type: 'select',
        optionsUrl: '/options/roles',
      },
      {
        name: 'birthday_month',
        label: 'Birthday Month',
        type: 'select',
        options: getOptionRange(1, 12),
      },
      {
        name: 'birthday_day',
        label: 'Birthday Day',
        type: 'select',
        options: getOptionRange(1, 31),
      },
    ],
    submitLabel: 'Search Users',
  };

  return (
    <AdminContainer>
      <SEO title="Users" />
      <Heading title="Users" links={actionLinks} />
      <Loadable loading={loading}>
        <AdminTable results={results} columns={columns} baseUrl={baseUrl} rowFormatter={formatRow} searchTitle="Search Users" searchForm={<AutoForm {...searchForm} />} />
      </Loadable>
    </AdminContainer>
  );
}

export default AdminUsers;
